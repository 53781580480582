<template>
  <v-container>
    <v-snackbar
      v-model="permSnackbar"
      :color="snackbarColor"
      :timeout="timeout"
    >
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card elevation="12" min-height="600">
      <v-row>
        <v-col cols="6">
          <v-card-title>I miei container ({{ stacks.length ? stacks.length :"0" }})</v-card-title>
        </v-col>
        
      </v-row>

      <v-card-text>
        <v-row v-show="loading" justify="center">
          <v-col cols="2">
            <br />
            <br />
            <br />
            <v-progress-circular
              color="primary"
              indeterminate
              :size="70"
              :width="7"
            />
            <br />
            <br />
            <br />
          </v-col>
        </v-row>
        <v-data-table
          v-show="!loading"
          elevation="10"
          :headers="headers"
          :items="stacks"
          item-key="NOME_STACK"
          :search="search"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            itemsPerPageText: 'Containers per pagina',
            itemsPerPageAllText: 'Tutti i containers',
            itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
            pageText: '',
          }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Filtra..."
              class="mx-4"
              
              itemsPerPageText="'Container per pagina'"
              clearable
            />
          </template>
          <template v-slot:no-data>
            <v-sheet> Nessun Container </v-sheet>
          </template>
          <template v-slot:no-results>
            <v-sheet> Nessun Container </v-sheet>
          </template>
          <!-- <template v-slot:item.id="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  :to="'/users/' + item.MATRICOLA"
                >
                  <v-icon small> mdi-eye-outline </v-icon>
                </v-btn>
              </template>
              <span>Visualizza</span>
            </v-tooltip>
          </template> -->

    <template v-slot:item.Status="{ item }">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
            <v-chip
              :color="item.Status == '1' ? 'success' : 'error'"
              dark
              small
              v-bind="attrs"
                  v-on="on"
            >
            <v-icon>{{ item.Status == '1' ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </v-chip>

        </template>
        <span>{{ item.Status == '1' ? 'Attivo' : 'Disattivato' }}</span>
          </v-tooltip>
          </template>

          <template v-slot:item.EndService="{ item }">
            {{
              item.EndService
            }}
          </template>

          <template v-slot:item.Owner="{ item }">
            {{
              item.Owner
            }}
          </template>
          <template v-slot:item.Description="{ item }">
            {{
              item.Description
            }}
          </template>
          <!--   <template v-slot:item.MATRICOLA="{ item }">
            <span :class="item.STATO === 'attivo' ? '' : 'text--disabled'">{{
              item.MATRICOLA
            }}</span>
          </template>
          <template v-slot:item.EMAIL="{ item }">
            <span :class="item.STATO === 'attivo' ? '' : 'text--disabled'">{{
              item.EMAIL
            }}</span>
          </template>
          <template v-slot:item.DS_RUOLO="{ item }">
            <span :class="item.STATO === 'attivo' ? '' : 'text--disabled'">{{
              item.DS_RUOLO
            }}</span>
          </template> -->
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src

//const axios = require('axios');

// import { mapState } from "vuex";

export default {
  name: "MyContainers",
  data: () => {
    return {
      // userTypes: [
      //   {
      //     TIPO_ORGANICO: "GUEST",
      //     DESCRIZIONE_TIPO_ORGANICO: "Guest/Visiting/Tesista",
      //     RUOLO: "GU",
      //     DS_RUOLO: "Guest/Visiting/Tesista",
      //   },
      //   {
      //     TIPO_ORGANICO: "STUDENT",
      //     DESCRIZIONE_TIPO_ORGANICO: "Student",
      //     RUOLO: "ST",
      //     DS_RUOLO: "Student",
      //   },
      //   {
      //     TIPO_ORGANICO: "EXTTEACHER",
      //     DESCRIZIONE_TIPO_ORGANICO: "External teacher",
      //     RUOLO: "GU",
      //     DS_RUOLO: "External teacher",
      //   },
      // ],
      // userType: null,
      // isFormValid: false,
      stacks: [],
      search: "",
      loading: false,
      // addUserDialog: false,
      permSnackbar: false,
      snackbarColor: "success",
      errorText: "",
      timeout: -1,
      // body: {
      //   MATRICOLA: "",
      //   COGNOME: "",
      //   NOME: "",
      //   TIPO_ORGANICO: "",
      //   DESCRIZIONE_TIPO_ORGANICO: "",
      //   RUOLO: "",
      //   DS_RUOLO: "",
      //   EMAIL: "",
      // },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Nome Stack",
          align: "start",
          sortable: true,
          value: "Name",
        },
        {
          text: "Descrizione",
          align: "end",
          sortable: false,
          value: "Description",
        },
        {
          text: "Responsabile",
          align: "end",
          sortable: true,
          value: "Owner",
        },
        {
          text: "Fine Servizio",
          align: "end",
          sortable: true,
          value: "EndService",
        },
        {
          text: "Stato",
          align: "center",
          sortable: true,
          value: "Status",
        },
      ];
    },
    // ...mapState(["userInitials", "userRole", "userPrivacy"]),
  },

  created: function () {
    this.loading = true;
    // this.loadUsers();
    this.loadMyStacks();
  },
  methods: {
    loadMyStacks() {
      this.loading = true;
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/stacks/" + this.$store.state.userMatricola + '/list', {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          this.stacks = response.data.map(item => ({
            Name: item.Name,
            Status: item.Status,
            Description: item.Env.find(item => item.name === "DESCRIPTION") ? item.Env.find(item => item.name === "DESCRIPTION").value : "N/A",
            Owner: item.Env.find(item => item.name === "OWNER_EMAIL") ? item.Env.find(item => item.name === "OWNER_EMAIL").value : "N/A",
            EndService: item.Env.find(item => item.name === "END_SERVICE") ? item.Env.find(item => item.name === "END_SERVICE").value : "N/A",
          }));
          //console.log(this.stacks);
          this.loading = false;
        })
        .catch((err) => {
          this.errorText = err.response.data.message;
          this.permSnackbar = true;
          this.snackbarColor = "red";
          this.loading = false;
        });
    },

  },
};
</script>